/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { BASE_URL } from 'config';
import {
  Banner,
} from 'components';

const StudentDiscount = () => (
  <Layout page="student-discount">

    <Helmet
      title="Haka Tours Student Discount"
      meta={[
        {
          name: 'description', content: 'Book a Haka Tour and save 10% as a Student Beans member.',
        },
        { name: 'og:title', content: 'Haka Tours Student Discount' },
        {
          name: 'og:description', content: 'Book a Haka Tour and save 10% as a Student Beans member.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/student-discount/` }]}
    />
    <Banner backgroundImage="https://static-hakatours.imgix.net/hakatours-student-discount.jpg?w=1900&h=600&fit=crop&auto=format">
      <h1 className="c-heading c-heading--h1">Hakatours Student Discount</h1>
    </Banner>
    <section className="l-section-main">
      <div className="l-container u-text--center">
        <h2 className="c-heading c-heading--h2">Book a Haka Tour and save 10% as a Student Beans member.</h2>
        <br /><br />
        <div className="u-text--center">
          Use our Haka Tours student discount code at the checkout to enjoy 10% off your tour.
        </div>
        <div className="u-text--center">
          For instant access to this discount simply register and verify your student status with Student Beans. <b>It's free!</b>
        </div>
        <br /><br />
        <div>
          <a href='https://haka-tours-nz.connect.studentbeans.com/nz' class="c-button c-button--primary c-button--wide">
            Click here to unlock code
          </a>
        </div>
      </div>
    </section>
  </Layout>
);

export default StudentDiscount;